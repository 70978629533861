import Head from 'next/head'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeMinimal } from '@supabase/auth-ui-shared'
import { useUser, useSupabaseClient, useSession } from '@supabase/auth-helpers-react'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { usePostHog } from 'posthog-js/react'

export default function Login() {
  const supabaseClient = useSupabaseClient()
	const session = useSession()
	const user = useUser()
	const posthog = usePostHog()
	const router = useRouter()

	useEffect(() => {
    if (session && user) {
			if (posthog) posthog.identify(user.email, {
				email: user.email
			})
      router.push(router.query.redirect? `/onboarding?redirect=${router.query.redirect}`: '/')
    }
  }, [session, user, posthog])

  return (
		<>
			<Head>
        <title>Login | Automata</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
			<main className="login container">
				<article className="grid p-0">
					<div className='p-2'>
						<h1>Sign in</h1>
						<Auth
							appearance={{ theme: ThemeMinimal }}
							supabaseClient={supabaseClient}
							providers={[]}
							socialLayout="horizontal"
							view='sign_in'
							showLinks={false}
							redirectTo='/'
						/>
						<p>
							<Link href='/forgot'>Forgot password?</Link> <br />
							<Link href='https://getautomata.app/#signup'>Create Account</Link>
							{/* <span onClick={() => console.log(router)}>Test</span> */}
						</p>
					</div>
					<div style={{
						display: 'block',
						backgroundColor: '#374956',
						backgroundImage: `url('background.jpg')`,
						backgroundPosition: 'center',
						backgroundSize: 'cover',
					}}></div>
				</article>
			</main>
		</>
  )
}
